export const pins = [
  "https://pl.pinterest.com/pin/300263500150694568/",
  "https://pl.pinterest.com/pin/293508100725156953/",
  "https://pl.pinterest.com/pin/400820435597509568/",
  "https://pl.pinterest.com/pin/23362491804179649/",
  "https://pl.pinterest.com/pin/134474738867457570/",
  "https://pl.pinterest.com/pin/47710077295520539/",
  "https://pl.pinterest.com/pin/7740630601397495/",
  "https://pl.pinterest.com/pin/829366087644926037/",
  "https://pl.pinterest.com/pin/1618549859803691/",
];
