export const offers = [
  {
    title: "Kompleksowa organizacja ślubu i wesela",
    paragraph: `Zorganizowanie ślubu i wesela wymaga dużo pracy i czasu. Dlatego warto zatrudnić do tego profesjonalistów,
        którzy pomogą Wam w zaplanowaniu tego dnia od A do Z. Wybierając tę usługę, nie tylko zaoszczędzicie dużo czasu, ale również
        zyskacie więcej spokoju w czasie organizacji, jak i w Dniu Ślubu. Będę towarzyszyć Wam od początku do końca i pomogę Wam sprawić,
        aby ten dzień przebiegł bez zakłóceń. Z wielką chęcią przejmę Wasze obowiązki związane z organizacją tego wydarzenia,
        doradzę i pomogę w podejmowaniu decyzji. Możecie liczyć na moją pomoc od pierwszego spotkania aż do Dnia Wesela.
        Będę z Wami przez cały ten czas i dopilnuję wszystkich przygotowań. Usługa kompleksowej realizacji ślubu i wesela realizowana jest na terenie Wielkopolski.`,
    list_title: `Usługa organizacji ślubu i wesela może zawierać`,
    list_items: [
      `spotkanie, na którym omówimy Wasze oczekiwania i preferencje`,
      `ustalenie i nadzór nad budżetem ślubnym`,
      "przygotowanie harmonogramu prac",
      "nadzór nad harmonogramem i realizacją prac",
      "pomoc w wyborze podwykonawców",
      "typowanie lokalizacji na przyjęcie weselne",
    ],
    picture: "organizacja.jpg",
    alt: "Nowożeńcy odchodzący od ołtarza, obrzuciani ryżem przez gości.",
  },
  {
    title: "Typowanie lokalizacji na przyjęcie weselne",
    paragraph: `Wybór miejsca na przyjęcie weselnego jest jednią z najistotniejszych decyzji do podjęcia podczas
        organizacji ślubu i wesela. Dlatego to właśnie od tego powinniście zacząć przygotowania. Ważne, abyście
        czuli się tam dobrze, aby to miejsce pasowało do koncepcji wesela. Typowanie to może być odrębną
        usługą lub możecie z niej skorzystać w ramach kompleksowej organizacji ślubu i wesela. Typowane przeze mnie lokalizacje na przyjecia weselne znajdują w obrębie województwa wielkopolskiego.`,
    list_title: `Usługa typowania lokalizacji na przyjęcie wesela zawiera`,
    list_items: [
      "spotkanie, na którym omówimy Wasze oczekiwania i preferencje",
      "wyszukiwanie przez Wedding Plannera miejsc weselnych, które odpowiadają Waszym oczekiwaniom",
      "przygotowanie i porównanie różnych ofert",
      "wstępne zarezerwowanie terminu",
      "wspólny rekonesans typowanych miejsc weselnych",
      "rezerwacja wybranego przez Was miejsca na przyjęcie weselne",
    ],
    picture: "miejsce.jpg",
    alt: "Para młoda przy barze.",
  },
  {
    title: "Koordynacja Dnia Ślubu i Wesela",
    paragraph: `Oferta koordynacji ślubu i wesela może być odrębną usługą lub można z niej skorzystać w ramach
        kompleksowej organizacji ślubu i wesela. W ramach tej usługi przygotujemy wspólnie scenariusz Dnia
        Ślubu. Będę Wam towarzyszyć od początku porannych przygotowań do późnych godzin nocnych.
        Zadbam o to, abyście w tym wyjątkowym dniu nie musieli się stresować i mogli cieszyć towarzystwem
        najbliższych. Usługa koordynacji Dnia Ślubu i Wesela realizowana jest na terenie Wielkopolski.`,
    list_title: `Usługa koordynacji dnia ślubu i wesela zawiera`,
    list_items: [
      `spotkanie, na którym omówimy Wasze oczekiwania i plan działania`,
      `przygotowanie oraz omówienie scenariusza Dnia Ślubu`,
      `koordynację przebiegu ceremonii zaślubin`,
      `koordynację prac realizowanych przez wszystkich podwykonawców`,
      `koordynację przybycia gości weselnych na miejsce ceremonii i przyjęcia weselnego`,
      `koordynację wszystkich ważnych momentów podczas ceremonii oraz przyjęcia weselnego`,
      `przygotowanie gości do powitania Młodej Pary`,
      `opiekę nad Młodą Parą w trakcie trwania ceremonii i przyjęcia weselnego`,
      `opiekę nad gośćmi w trakcie trwania przyjęcia weselnego`,
      `koordynację przyjęcia weselnego`,
      `rozwiązywanie bieżących problemów oraz pomoc w trudnych i niespodziewanych sytuacjach`,
      `ostateczne rozliczenia z podwykonawcami`,
    ],
    picture: "koordynacja.jpg",
    alt: "Objęci nowożeńcy otoczeni gośćmi weselnymi.",
  },
  {
    title: "Częściowa organizacja ślubu i wesela",
    paragraph: `Jeśli zorganizowaliście już większość elementów tego ważnego dnia, idealnym rozwiązaniem dla Was
        będzie skorzystanie z usługi częściowej koordynacji ślubu. Wspólnie ustalimy, co już zostało zrobione i
        zorganizujemy całą resztę. Usługa częściowej organizacji ślubu i wesela realizowana jest na terenie Wielkopolski.`,
    list_title: null,
    list_items: [],
    picture: "czesciowa-organizacja.jpg",
    alt: "Panna młoda z bukietem.",
  },
];
